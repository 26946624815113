/* eslint-disable */
import {RAMBLER_WIDGET_KEY} from "app/constants/SettingsConstants";

const ramblerScript = document.createElement('script');
const ramblerKey = document.createElement('rb:rich');

ramblerScript.setAttribute('type', 'text/javascript');
ramblerScript.setAttribute('src', '//kassa.rambler.ru/s/widget/js/TicketManager.js');
ramblerKey.setAttribute('key', RAMBLER_WIDGET_KEY);
ramblerKey.setAttribute('xmlns:rb', 'https://api.afisha.ru');

document.body.appendChild(ramblerKey);
document.body.appendChild(ramblerScript);